@font-face {
  font-family: 'Raleway';
  src: URL('../src/assets/fonts/Raleway-Light.ttf') format('truetype');
}

body {
  overflow-x: hidden;
  background: url('../src/assets/WaveBg.jpg') no-repeat center center fixed;
  background-size: cover;
}

body:after {
  content: "";
  position: fixed;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
  z-index: -1;
  background: url('../src/assets/WaveBg.jpg') center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.App {
  text-align: center;
  font-family: Raleway;
}

/* Landing */

#Landing {
  width: 100%;
  background: rgb(176, 226, 247);
}

.landingDiv {
  color: black;
  margin: auto;
  text-align: center;
  padding-top: 40vh;
  z-index: 300;
  position: relative;
}

.landingButton {
  z-index: 30;
}

.landingText {
  margin-bottom: 4%;
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1)
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55)
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1)
  }
}

.waveWrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  bottom: -1px;
}
.bgTop {
  z-index: 15;
  opacity: 0.5;
}
.bgMiddle {
  z-index: 10;
  opacity: 0.75;
}
.bgBottom {
  z-index: 5;
}
.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100vh;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}

.waveAnimation .waveTop {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.waveMiddle {
  background-size: 50% 120px;
}
.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}
.waveBottom {
  background-size: 50% 100px;
}
.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}

/* Navbar */

.navLink {
  font-size: 30px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  list-style: none;
  margin: auto;
}

.hvr-grow-rotate {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.hvr-grow-rotate:hover, .hvr-grow-rotate:focus, .hvr-grow-rotate:active {
  cursor: pointer;
  text-shadow: 0 0 10px rgba(216, 216, 216, 0.719);
  -webkit-transform: scale(1.3) rotate(4deg);
  transform: scale(1.3) rotate(4deg);
}


.scrollspy {
  width: 100%;
  height: 6vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background-color: rgba(228, 228, 228, 0.97);
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 5;
  box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}



.isCurrent {
  text-decoration-line: underline;
  color: rgba(87, 140, 253, 0.863);
  text-shadow: 2px 2px 10px rgba(92, 92, 92, 0.719);
  -webkit-transform: scale(1.2) rotate(4deg);
  transform: scale(1.2) rotate(4deg);
}

/* Home */

#homePage {
  text-align: center;
  padding-bottom: 5%;
}

/* About */

#about {
  padding-top: 4%;
  padding-bottom: 5%;
}

.tanner {
  width: 100%;
  height: 100%;
  border-radius: 3%;
  padding: 5px;
}

.bigCard {
  background-color: rgba(230, 230, 230, .85);
  border: 2px solid black;
  width: 80%;
  padding: 20px;
  margin: 4% auto 4% auto;
  display: grid;
  grid-template-columns: 1fr 3fr;
  box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
}

.aboutText {
  font-size: 18px;
  padding: 2%;
}

.card {
  background-color: rgba(230, 230, 230, .85);
  border: 2px solid black;
  padding: 10px;
  margin: auto;
  box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
}

.bioTitle {
  margin-bottom: 2%;
  color: rgba(87, 140, 253, 0.863);
  font-size: 60px;
  text-shadow: 2px 2px black;
}

/* Tech Stack */

.techStack {
  background-color: rgba(230, 230, 230, .85);
  border: 2px solid black;
  padding: 10px;
  margin: auto;
  width: 40%;
  box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
}

.techGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}

.logoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-column-gap: 5%;
  margin: 10% auto 10% auto;
}

.logoText {
  margin: auto;
  font-weight: bolder;
}

.techTitle {
  text-decoration: underline;
}

.techLogo {
  width: 60px;
  height: 40px;
}

/* Projects */

#projects {
  margin-top: 2%;
  padding-top: 2%;
}



.miniTitle {
  color: rgba(87, 140, 253, 0.863);
  font-size: 60px;
  text-shadow: 2px 2px black;
  margin: 3%;
}

.allWorkDone {
  margin-top: 5%;
  margin-bottom: 10%;
  background-color: rgba(230, 230, 230, .85);
  padding-bottom: 3%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.sectionGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

.sectionGridGames {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}

.personalSectionGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}

.personalProjects {
  margin-top: 5%;
  margin-bottom: 10%;
  background-color: rgba(230, 230, 230, .85);
  padding-bottom: 3%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.project {
  display: block;
  margin-top: 30px;
  margin: 2% 2% 2% 2%;
  background-color: rgba(88, 160, 255, 0.363);;
  border: 2px solid black;
  border-radius: 10px;
  padding: 20px;
  box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
}

.projectText {
  padding: 2%;
  margin: 0 5% 0 5%;
}

/* Float Shadow */
.hvr-float-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-float-shadow:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
}
.hvr-float-shadow:hover, .hvr-float-shadow:focus, .hvr-float-shadow:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  /* move the element up by 5px */
}
.hvr-float-shadow:hover:before, .hvr-float-shadow:focus:before, .hvr-float-shadow:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}

.alleywayEvents {
  width: 20vw;
  height: 20vh;
  color: white;
  background-image: url('../src/assets/alleywayevents.PNG');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.joystickForums {
  width: 20vw;
  height: 20vh;
  color: white;
  background-image: url('../src/assets/joystickforums.PNG');
  background-position-x: 45%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.tapRoom {
  width: 20vw;
  height: 20vh;
  color: white;
  background-image: url('../src/assets/tapRoom.PNG');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


.kninecs {
  width: 20vw;
  height: 20vh;
  color: white;
  background-image: url('../src/assets/kninecs.PNG');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.primeglass {
  width: 20vw;
  height: 20vh;
  color: white;
  background-image: url('../src/assets/primeGlass.PNG');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* Games */

#games {
  padding-top: 2%;
}

.gamesSection {
  margin-top: 5%;
  margin-bottom: 10%;
  background-color: rgba(230, 230, 230, .85);
  padding-bottom: 3%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.spaceboi {
  width: 20vw;
  height: 20vh;
  color: white;
  background-image: url('../src/assets/spaceboi.PNG');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.infiniteRunner {
  width: 20vw;
  height: 20vh;
  color: white;
  background-image: url('../src/assets/SimpleRunJumpClip2D.gif');
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-position-y: bottom;
}



/* Contact */

#contact {
  padding-top: 2%;
}

.contactCard {
  margin: auto;
  width: 50%;
  background-color: rgba(230, 230, 230, .85);
  padding: 3%;
  border-radius: 2%;
}

.contactLabel {
  font-size: 20px;
  color: black;
}

.submitButton {
  background-color: rgb(67, 164, 228) !important;
}

/* Media Query */

@media screen and (max-width: 900px) {

  #Landing {
    width: 100%;
    background: rgb(176, 226, 247);
  }

  .landingText {
    font-size: 30px;
    margin: auto;
    margin-top: -50%;
    margin-bottom: 10%;
    width: 80vw;
  }

  @keyframes move_wave {
    0% {
      transform: translateX(0) translateZ(0) scaleY(.5)
    }
    50% {
      transform: translateX(-25%) translateZ(0) scaleY(0.3)
    }
    100% {
      transform: translateX(-50%) translateZ(0) scaleY(.5)
    }
  }

  .scrollspy {
    padding-left: 0;
  }

  .navLink {
    font-size: 20px;
  }

  .isCurrent {
    text-decoration-line: underline;
    color: rgb(0, 0, 0);
    text-shadow: 0 0 10px rgba(216, 216, 216, 0.719);
  }


  .bigCard {
    grid-template-rows: .2fr 1fr;
    grid-template-columns: none;
  }


  .techGrid {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
  }

  .sectionGrid {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
  }

  .sectionGridGames {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
  }

  .personalSectionGrid {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
  }

  .spaceboi {
    width: 80vw;
  }

  .alleywayEvents {
    width: 80vw;
  }

  .joystickForums {
    width: 80vw;
  }

  .kninecs {
    width: 80vw;
  }

  .primeglass {
    width: 80vw
  }

  .tapRoom {
    width: 80vw
  }

  .infiniteRunner {
    width: 80vw;
  }

  .contactCard {
    width: 80%;
  }

  .hvr-grow-rotate {
    -webkit-transform: none;
    transform: none;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);

  }

  .hvr-grow-rotate:hover, .hvr-grow-rotate:focus, .hvr-grow-rotate:active {
    text-shadow: 0 0 10px rgba(216, 216, 216, 0.719);
    -webkit-transform: none;
    transform: none;
  }

  #about {
    padding-top: 10%;
  }

  #projects {
    padding-top: 10%;
  }

  #games {
    padding-top: 10%;
  }

  .aboutText {
    font-size: 14px;
  }

  .bioTitle {
    font-size: 40px;
  }

  .miniTitle {
    font-size: 45px;
  }

    /* Tech Stack */

  .techGrid {
    grid-template-rows: .8fr 1fr;
    grid-template-columns: none;
  }

  .techStack {
    background-color: rgba(230, 230, 230, .85);
    border: 2px solid black;
    padding: 10px;
    margin: auto;
    width: 75%;
    box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
  }

  .logoGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    margin: 10% auto 10% auto;
  }

  .logoText {
    margin: auto;
    font-weight: bolder;
    font-size: 14px;
  }

  .techTitle {
    text-decoration: underline;
    font-size: 30px;
  }

  .techLogo {
    width: 30px;
    height: 20px;
  }

}

